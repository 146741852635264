<template>
    <v-card id="details" :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>
                    mdi-close
                </v-icon>
            </v-btn>
            <h3>Delete Request</h3>
        </v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-subtitle class="mt-8">
            <div>
                <p class="pa-0 mx-0 my-2">
                    <strong>CODE: </strong>{{ request.code }}
                </p>
            </div>
            <div>
                <p class="pa-0 mx-0 my-2">
                    <strong>Description: </strong>{{ request.description }}
                </p>
            </div>
            <div>
                <p class="pa-0 mx-0 my-2">
                    <strong>Notes: </strong>{{ request.notes }}
                </p>
            </div>
        </v-card-subtitle>
        <v-card-actions>
            <v-spacer />
            <v-btn
                color="red"
                dark
                class="mr-2 mb-3"
                rounded
                @click="deleteRequest"
                :loading="loading"
            >
                DELETE
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'

export default {
    name: 'DeleteRequest',
    props: {
        request: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        loading: false,
    }),
    methods: {
        ...mapMutations(['setErrorItems']),
        async deleteRequest() {
            try {
                this.loading = true
                await API.deleteRequest(this.request.id)
                this.$emit('deleteRequest', this.request.id)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('closeDialog')
        },
    },
}
</script>

<style></style>
