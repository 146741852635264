var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{attrs:{"id":"details","loading":_vm.loading}},[_c('v-card-title',[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","icon":"","color":"primary"},on:{"click":_vm.close}},[_c('v-icon',[_vm._v(" mdi-close ")])],1),_c('h3',[_vm._v("Edit Request")])],1),_c('v-divider',{staticClass:"ml-4 mr-5"}),_c('v-card-text',{staticClass:"mt-n5"},[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"my-6",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"hide-details":"","label":"Code","prepend-icon":"mdi-barcode","disabled":!_vm.approveStock ||
                                    _vm.currentStatus == 'rejected' ||
                                    _vm.currentStatus == 'approved'},model:{value:(_vm.request.code),callback:function ($$v) {_vm.$set(_vm.request, "code", $$v)},expression:"request.code"}})],1)],1),_c('v-row',{staticClass:"my-6",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.status,"item-text":"text","item-value":"value","hide-details":"","label":"Select Status","prepend-icon":"mdi-list-status","disabled":!_vm.approveStock ||
                                    _vm.currentStatus == 'approved' ||
                                    _vm.currentStatus == 'rejected',"rules":[_vm.rules.required],"required":""},model:{value:(_vm.request.status),callback:function ($$v) {_vm.$set(_vm.request, "status", $$v)},expression:"request.status"}})],1)],1),_c('v-row',{staticClass:"my-6",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"hide-details":"","label":"Description","prepend-icon":"mdi-book-open-outline","rules":[_vm.rules.required],"required":"","disabled":_vm.currentStatus == 'approved' ||
                                    _vm.currentStatus == 'rejected'},model:{value:(_vm.request.description),callback:function ($$v) {_vm.$set(_vm.request, "description", $$v)},expression:"request.description"}})],1)],1),_c('v-row',{staticClass:"my-6",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"rows":"2","hide-details":"","label":"Notes","prepend-icon":"mdi-text","disabled":_vm.currentStatus == 'approved' ||
                                    _vm.currentStatus == 'rejected'},model:{value:(_vm.request.notes),callback:function ($$v) {_vm.$set(_vm.request, "notes", $$v)},expression:"request.notes"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mr-2 mb-3",attrs:{"color":"primary","rounded":"","disabled":!_vm.valid ||
                        _vm.currentStatus == 'approved' ||
                        _vm.currentStatus == 'rejected',"loading":_vm.loading},on:{"click":_vm.updateRequest}},[_vm._v(" SAVE ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }