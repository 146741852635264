<template>
    <div>
        <v-card id="details" :loading="loading">
            <v-card-title>
                <v-btn small icon @click="close" color="primary" class="mr-2">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
                <h3>Edit Request</h3>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text class="mt-n5">
                <v-form v-model="valid">
                    <v-row no-gutters class="my-6">
                        <v-col cols="12">
                            <v-text-field
                                hide-details
                                v-model="request.code"
                                label="Code"
                                prepend-icon="mdi-barcode"
                                :disabled="
                                    !approveStock ||
                                        currentStatus == 'rejected' ||
                                        currentStatus == 'approved'
                                "
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="my-6">
                        <v-col cols="12">
                            <v-autocomplete
                                v-model="request.status"
                                :items="status"
                                item-text="text"
                                item-value="value"
                                hide-details
                                label="Select Status"
                                prepend-icon="mdi-list-status"
                                :disabled="
                                    !approveStock ||
                                        currentStatus == 'approved' ||
                                        currentStatus == 'rejected'
                                "
                                :rules="[rules.required]"
                                required
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="my-6">
                        <v-col cols="12">
                            <v-text-field
                                v-model="request.description"
                                hide-details
                                label="Description"
                                prepend-icon="mdi-book-open-outline"
                                :rules="[rules.required]"
                                required
                                :disabled="
                                    currentStatus == 'approved' ||
                                        currentStatus == 'rejected'
                                "
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="my-6">
                        <v-col cols="12">
                            <v-textarea
                                v-model="request.notes"
                                rows="2"
                                hide-details
                                label="Notes"
                                prepend-icon="mdi-text"
                                :disabled="
                                    currentStatus == 'approved' ||
                                        currentStatus == 'rejected'
                                "
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="primary"
                    class="mr-2 mb-3"
                    rounded
                    :disabled="
                        !valid ||
                            currentStatus == 'approved' ||
                            currentStatus == 'rejected'
                    "
                    @click="updateRequest"
                    :loading="loading"
                >
                    SAVE
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'

export default {
    name: 'RequestForm',
    props: {
        request: {
            type: Object,
            default: () => {},
        },
        approveStock: {
            type: String,
            default: () => '',
        },
    },
    data: () => ({
        valid: false,
        loading: false,
        currentStatus: null,
        status: [
            { text: 'On Hold', value: 'onHold' },
            { text: 'Approved', value: 'approved' },
            { text: 'Rejected', value: 'rejected' },
        ],
        rules: {
            required: v => !!v || 'The value is required!',
        },
    }),
    mounted() {
        this.currentStatus = this.request.status
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        close() {
            this.$emit('closeDialog')
        },
        async updateRequest() {
            try {
                this.loading = true
                const request = await API.updateRequest({
                    id: this.request.id,
                    code: this.request.code,
                    status: this.request.status,
                    description: this.request.description,
                    notes: this.request.notes || '',
                })
                this.$emit('updateRequest', request)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style></style>
